import { Controller } from '@hotwired/stimulus'
import CustomEvents from '../custom/customEvents'
const searchFormInputChangedEvent = new CustomEvents().searchFormInputChangedEvent()

export default class extends Controller {
  static targets = []

  connect () {
    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap () {
    this.initializeAutocomplete('js-search_location_display_autocomplete')
    this.initializeAutocomplete(
      'js-search_location_display_autocomplete-mobile'
    )
    this.initializeAutocomplete('car_street')
  }

  initializeAutocomplete (id) {
    let autocomplete
    let options
    const element = document.getElementById(id)
    if (element) {
      if (id === 'car_street') {
        options = { types: ['geocode'] }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger map car_street
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onPlaceChanged
        )
      }
      if (id === 'js-search_location_display_autocomplete') {
        options = {
          fields: ['name', 'place_id', 'address_components'],
          componentRestrictions: { country: ['fr', 'mc'] }
        }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger search_location_display_autocomplete
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onCityChanged
        )
      }

      if (id === 'js-search_location_display_autocomplete-mobile') {
        options = {
          fields: ['name', 'place_id', 'address_component'],
          componentRestrictions: { country: 'fr' }
        }
        autocomplete = new google.maps.places.Autocomplete(element, options)

        // Trigger search_location_display_autocomplete mobile
        google.maps.event.addListener(
          autocomplete,
          'place_changed',
          this.onCityChanged
        )
      }

      google.maps.event.addDomListener(element, 'keydown', function (e) {
        if (e.keyCode === 13) {
          e.preventDefault()

          setTimeout(function () {
            document.querySelector('#home-search').submit()
          }, 700)
        }
      })
    }
  }

  onPlaceChanged () {
    const place = this.getPlace()
    const components = getAddressComponents(place, 'fulladdress')

    document.querySelector('#car_street').value = components.address
    document.querySelector('#car_city').value = components.city
  }

  onCityChanged () {
    const place = this.getPlace()
    const components = getAddressComponents(place, 'country')

    if (components.address && components.city) {
      document.querySelector('#js-search_location_display_autocomplete').value =
        components.address
      document.querySelector('#js-search_location_autocomplete').value =
        components.city
    } else {
      document.querySelector('#js-search_location_autocomplete').value = ''
    }
    document.dispatchEvent(searchFormInputChangedEvent)
  }
}

function getAddressComponents (place, outputType) {
  let street_number = null
  let route = null
  let zip_code = null
  let city = null
  let country = null

  for (const i in place.address_components) {
    const component = place.address_components[i]
    for (const j in component.types) {
      const type = component.types[j]
      if (type === 'street_number') {
        street_number = component.long_name
      } else if (type === 'route') {
        route = component.long_name
      } else if (type === 'postal_code') {
        zip_code = component.long_name
      } else if (type === 'locality') {
        city = component.long_name
      } else if (type === 'country') {
        country = component.long_name
      }
    }
  }
  if (outputType === 'fulladdress') {
    return {
      address: street_number == null ? route : street_number + ' ' + route,
      street_number,
      route,
      zip_code,
      city,
      country
    }
  }
  if (outputType === 'country') {
    const address = city && zip_code ? zip_code + ' ' + city : city
    return {
      address,
      city: address
    }
  }
}
