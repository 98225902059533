export default class CustomEvents {
  static EVENTS_KEY = {
    'filters-changed': 'filters-changed',
    'search-form-input-changed': 'search-form-input-changed',
    'gpay-response': 'gpay-response',
    'multiselect-closed': 'multiselect-closed',
    'multiselect-ready': 'multiselect-ready'
  }

  multiselectClosed(payload){
    return new CustomEvent(CustomEvents.EVENTS_KEY['multiselect-closed'], payload)
  }
  filtersChangedEvent () {
    return new CustomEvent(CustomEvents.EVENTS_KEY['filters-changed'])
  }

  searchFormInputChangedEvent () {
    return new CustomEvent(CustomEvents.EVENTS_KEY['search-form-input-changed'])
  }

  multiselectDropdownReadyEvent () {
    return new CustomEvent(CustomEvents.EVENTS_KEY['multiselect-ready'])
  }
}
