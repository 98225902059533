let search_booking_starts_on
const apply_hours_filter = function () {
  const bookingStartsAtSelector = document.querySelector('#starts_at')

  const now = new Date(Date.now())
  const today_is_selected =
    flatpickr.formatDate(now, 'd/m/Y') === search_booking_starts_on.value
  let previousElementSelected = false

  for (const element of bookingStartsAtSelector.options) {
    const hours_option = element.value
    const hours_option_split = hours_option.split(':')
    const hours = parseInt(hours_option_split[0])
    const minutes = parseInt(hours_option_split[1])
    if (today_is_selected) {
      if (
        hours < now.getHours() ||
        (hours === now.getHours() && minutes <= now.getMinutes())
      ) {
        if (element.selected) {
          previousElementSelected = true
        }
        element.disabled = 'disabled'
        element.removeAttribute('selected')
      } else if (previousElementSelected) {
        element.selected = 'selected'
        break
      }
    } else {
      element.removeAttribute('disabled')
    }
  }
}
const search_calendar_service = function () {
  if (document.querySelectorAll('.search-datepicker').length) {
    search_booking_starts_on = document.querySelector(
      '#search_booking_starts_on'
    )
    // Flatpickr setup
    flatpickr('.search-datepicker', {
      minDate: 'today',
      altInput: false,
      dateFormat: 'd/m/Y',
      position: 'below',
      locale: 'fr',
      disableMobile: true,
      plugins: [new rangePlugin({ input: '#search_booking_ends_on' })]
    })
    apply_hours_filter()


    search_booking_starts_on.addEventListener('change', function () {
      apply_hours_filter()
    })
  }
}

document.addEventListener('ready', function () {
  search_calendar_service()
})

document.addEventListener('turbo:load', function () {
  search_calendar_service()
})
